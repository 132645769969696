import { getAPI, deleteAPI, postAPI } from '../../utils/fetchApiUtils.js'
import {
  SET_CART_LENGTH,
  SET_CART,
  DELETE_CART_PRODUCT,
  SUBSTRACT_CART_LENGTH,
  SET_CART_SHIPPING_OPTIONS,
  SET_USER_ORDERS,
  SET_USER_QUOTATIONS,
  SET_ORDER_INFO,
  SET_PRODUCT_COMMENTS,
  SET_PDF_DETAIL,
} from '../constants/actionTypes'

const getCartLength = (dispatch) => async (cartId) => {
  const res = await getAPI(`/cart/${cartId}/count`, true)
  console.log('res getCartLength is: ', res)

  dispatch({
    type: SET_CART_LENGTH,
    payload: { cartLength: res.data ? res.data.count : 0 },
  })

  return { cartUuid: res.data ? res.data.cartId : '' }
}

const getCartProducts =
  (dispatch) =>
  async ({ cartUuid, isLogin }) => {
    const res = await getAPI(`/cart/${cartUuid}/products`, isLogin)
    const res1 = await getAPI(`/cart/manualPaymentDisplay`)
    console.log('res cart pd is: ', res)
    // FIXME: use status code or else to determination
    const products = res.message === 'no products in cart' ? [] : res.data?.products
    // products.map(async product => {
    //   await getProduct(product.entryId)
    //   // get Contentful
    // })

    dispatch({
      type: SET_CART,
      payload: {
        id: cartUuid,
        ...res.data,
        products,
        allowManualPayment: res1.display,
      },
    })
  }

const deleteCartProduct =
  (dispatch) =>
  async ({ cartUuid, productUuid }) => {
    const res = await deleteAPI(`/cart/${cartUuid}/product/${productUuid}`)
    console.log('res is: ', res)

    // if (res.statusCode !== 200) return

    // dispatch({
    //   type: DELETE_CART_PRODUCT,
    //   payload: { productUuid },
    // })

    dispatch({
      type: SUBSTRACT_CART_LENGTH,
      // payload: { memberInfo: {}, },
    })
  }

const getCartShippingOptions = (dispatch) => async () => {
  const res = await getAPI('/cart/shippingOptions')
  console.log('res is: ', res)

  // if (res.statusCode !== 200) return

  dispatch({
    type: SET_CART_SHIPPING_OPTIONS,
    payload: res,
  })
}

const getUserOrders =
  (dispatch) =>
  async (page = 1) => {
    const res = await getAPI(`/user/orders?page=${page}`, true)
    dispatch({
      type: SET_USER_ORDERS,
      payload: res,
    })
  }

const getUserQuotations =
  (dispatch) =>
  async (page = 1) => {
    const res = await getAPI(`/user/quotations?page=${page}`, true)
    dispatch({
      type: SET_USER_QUOTATIONS,
      payload: res,
    })
  }

const getOrderByID = (dispatch) => async (orderID) => {
  if (orderID == undefined) return
  const res = await getAPI(`/order/${orderID}`, true)
  const res1 = await getAPI(`/cart/manualPaymentDisplay`)
  console.log('order res: ', res)

  dispatch({
    type: SET_ORDER_INFO,
    payload: {
      ...res.data,
      allowManualPayment: res1.display,
    },
  })
}

const getProductCommentsByID = (dispatch) => async (productID, orderID) => {
  if (productID === undefined || orderID === undefined) return

  const res = await getAPI(`/orderProduct/${productID}/comments?orderId=${orderID}`, true)
  console.log('product comment res: ', res)

  dispatch({
    type: SET_PRODUCT_COMMENTS,
    payload: {
      ...res.data,
    },
  })
}

const getPDFDetail = (dispatch) => async (type, itemID) => {
  if (itemID === undefined) return
  let res
  switch (type) {
    case 'invoice':
      res = await getAPI(`/order/${itemID}`, true)
      dispatch({
        type: SET_PDF_DETAIL,
        payload: {
          ...res.data,
          ...res.data.order,
        },
      })
      break
    case 'quotation':
      res = await getAPI(`/quotation/${itemID}`)
      dispatch({
        type: SET_PDF_DETAIL,
        payload: {
          ...res.data,
          priceInfo: {
            subTotal: res.data.subTotal,
            totalVarietyOrderDiscount: res.data.totalVarietyOrderDiscount,
            freight: res.data.freight,
            urgentFee: res.data.urgentFee,
            points: res.data.points,
            coupon: res.data.coupon,
            paymentAmount: res.data.paymentAmount,
          },
          products: res.data.products.map((item) => {
            return {
              ...item,
              ...item.information,
              title: item.information.productTitle,
              additionList: item.information.additionOptionNameList,
            }
          }),
        },
      })
      break
    default:
      return
  }
}

const createOrder = (dispatch) => async (payload) => {
  const res = await postAPI('/order/validateAndCreate', true, payload)
  console.log('res in create order is: ', res)

  // if (res.statusCode !== 200) return
  // if (res.statusCode === 201) {

  // dispatch({
  //   type: SET_CART_SHIPPING_OPTIONS,
  //   payload: res,
  // })

  return res
}

export {
  getCartLength,
  getCartProducts,
  deleteCartProduct,
  getCartShippingOptions,
  getOrderByID,
  getProductCommentsByID,
  getPDFDetail,
  getUserOrders,
  getUserQuotations,
  createOrder,
}
