import { SET_SELECTED_CATEGORY_ID } from '../constants/actionTypes'

const setSelectedCategoryId = (dispatch) => (categoryId) => {
  dispatch({
    type: SET_SELECTED_CATEGORY_ID,
    payload: { categoryId },
  })
}

export { setSelectedCategoryId }
